import * as React from 'react'

export default function Footer() {
    return (
      <footer className="bg-hely-lightgreybg mt-auto" aria-labelledby="footer-heading">
        <div className="max-w-7xl mx-auto pb-12 px-4 sm:px-6 lg:pb-9 lg:px-8">
            <div className="border-t border-gray-200 pt-8 md:flex md:items-center md:justify-between">
                <p className="mt-8 text-base text-gray-400 md:mt-0 md:order-1">
                &copy; Copyright 2024 Helygan Digital Ltd. Registered in England and Wales No. 9354524.
                </p>
            </div>
        </div>
      </footer>
    )
  }
  