import * as React from 'react'
import { Link } from 'gatsby'
import logo from '../images/logo.png'

const Header = ({ displayBackHomeButton }) => {

  let buttonClass = displayBackHomeButton ? 'bg-hely-slate' : 'bg-hely-orange';
  let buttonText = displayBackHomeButton ? 'Back' :  'Contact Us';
  let buttonLink = displayBackHomeButton ? '/' : '/contact';

  return (
    <div className="bg-white">
      <header class="relative bg-hely-lightgreybg">
        <div className="flex justify-between items-center max-w-7xl mx-auto px-4 py-5 sm:px-6 md:justify-start md:space-x-10 lg:px-8">
          <div className="flex flex-1 justify-start">
            <Link to="/">
              <img src={logo} alt="" className="h-10 w-auto md:h-14" />
            </Link>
          </div>
          <div className="md:flex items-center justify-end md:flex-1 lg:w-0">
            <Link 
              to={buttonLink}
              className={ 'ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white hover:bg-hely-orangehover ' + buttonClass }
            >{buttonText}</Link>
          </div>
        </div>
      </header>
     </div>
  )
}

export default Header
