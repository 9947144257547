import * as React from 'react'
import Header from './header'
import Footer from './footer'

const Layout = ({ pageTitle, wrapperClass, headerDisplayBackButton, children }) => {
  return (
    <div className={wrapperClass}>
      <title>{pageTitle}</title>
      <Header displayBackHomeButton={headerDisplayBackButton} />
      {children}
      <Footer />
    </div>
  )
}

export default Layout